<template>
  <div class="base-platform__gtm-login-page">
    <div class="gtm-login-main">
      <div class="gtm-login-header" @click="onLogoClick">
        <img v-if="tenantLogoShow" :src="headerLogoSrc" alt=""/>
        <h2 class="system-title">
          <span>{{ headerText }}</span>
        </h2>
      </div>
      <div class="gtm-login-content">
        <div class="gtm-login-box">
          <div class="content-desc">
            <div class="top-line">开启电子保函时代</div>
            <div class="foot-line">省时 · 省力 · 省钱 · 省心</div>
          </div>
          <div :class="isUserNameRegister ? 'login-box--username' : 'login-box'">
            <gtm-loading-box :show.sync="boxLoading" :message="boxMessage">
              <div class="login-form" :class="isUserNameRegister ? 'login-form--register' : ''">
                <div v-if="boxLoading" class="login-triangle-loading"></div>
                <div class="login-triangle" @click="onActionTypeClick">
                  <div>{{ actionTypeText }}</div>
                </div>
                <div class="form-title">Hi，欢迎来到金服平台</div>
                <!-- 登录表单 -->
                <login-form
                  v-if="actionType === 'Login'"
                  @handle-login-tab-change="handleFormTypeChange"
                  @handle-login-loading-change="handleLoginLoadingChange"
                  :login-type="loginType"
                >
                </login-form>
                <!-- 注册表单 -->
                <register-form
                  v-if="actionType === 'Register'"
                  @handle-register-tab-change="handleFormTypeChange"
                  @handle-login-click="handleLoginClick"
                  @handle-register-success="handleRegisterSuccess"
                  @handle-register-loading-change="handleRegisterLoadingChange"
                >
                </register-form>
              </div>
            </gtm-loading-box>
          </div>
        </div>
      </div>
    </div>
    <div class="gtm-login-footer">
      <gtm-login-footer></gtm-login-footer>
    </div>
  </div>
</template>

<script>
import LoginForm from './components/LoginForm'
import GtmLoginFooter from './components/GtmLoginFooter'
import RegisterForm from './components/RegisterForm'

export default {
  name: 'GtmLoginPage',
  components: { LoginForm, RegisterForm, GtmLoginFooter },
  mixins: [],
  props: {},
  data () {
    return {
      actionType: 'Login',
      loginType: 'mobileLogin',
      formType: '',
      isRegisterSuccess: false,
      boxMessage: '即将登录',
      boxLoading: false,
      tenantLogoShow: true,
      headerLogoSrc: '',
      headerText: '广联达金服平台'
    }
  },
  computed: {
    actionTypeText () {
      return this.actionType === 'Login' ? '注册' : '登录'
    },
    isUserNameRegister () {
      return this.formType === 'userNameRegister' && !this.isRegisterSuccess
    }
  },
  mounted () {
    if (this.$route.query.type === 'register') {
      this.actionType = 'Register'
      this.$rootMethods.DATA_TRACKING.sendTrackData('1515001')
    } else {
      this.$rootMethods.DATA_TRACKING.sendTrackData('1514001')
    }
    this.updateLogo()
  },
  methods: {
    updateLogo () {
      if (this.$rootState?.tenantConfig?.header?.logoSrc) {
        this.headerLogoSrc = this.$rootState?.tenantConfig?.header?.logoSrc
      } else if (this.$rootState?.tenantConfig?.header?.logoSrc === '') {
        // 这里是要兼容无logo的情况
        this.tenantLogoShow = false
      } else {
        this.headerLogoSrc = require('@/assets/images/gtm-login/login-logo.png')
      }
      if (this.$rootState?.tenantConfig?.header?.platformName) {
        this.headerText = this.$rootState?.tenantConfig?.header?.platformName
        document.title = this.$rootState?.tenantConfig?.header?.platformName
      }
    },
    onLogoClick () {
      this.$router.push({
        path: this.$store.state.defaultMenuInfo.defaultPath
      })
    },
    onActionTypeClick () {
      this.actionType = this.actionType === 'Login' ? 'Register' : 'Login'
      this.formType = this.actionType === 'Login' ? 'mobileLogin' : 'mobileRegister'
      this.isRegisterSuccess = false
      if (this.actionType === 'Login') {
        this.$rootMethods.DATA_TRACKING.sendTrackData('1514001')
      } else {
        this.$rootMethods.DATA_TRACKING.sendTrackData('1515001')
      }
    },
    handleFormTypeChange (value) {
      this.formType = value
      this.isRegisterSuccess = false
    },
    handleLoginLoadingChange (value) {
      this.boxMessage = '即将登录'
      this.boxLoading = value
    },
    handleLoginClick (registerType) {
      this.actionType = 'Login'
      if (registerType === 'mobileRegister') {
        this.loginType = 'mobileLogin'
      } else {
        this.loginType = 'userNameLogin'
      }
    },
    handleRegisterSuccess () {
      this.isRegisterSuccess = true
    },
    handleRegisterLoadingChange (value) {
      this.boxMessage = '即将注册'
      this.boxLoading = value
    }
  }
}
</script>
<style lang="scss">
.base-platform__gtm-login-page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  //display: flex;
  //flex-direction: column;

  .gtm-login-main {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 88px;
    background: url(../../../assets/images/gtm-login/login-bg.jpg) center center no-repeat;
    background-size: cover;
  }

  .gtm-login-header {
    position: absolute;
    top: 30px;
    left: 40px;
    display: flex;
    cursor: pointer;
    z-index: 1;

    img {
      width: 48px;
      height: 48px;
      display: inline-block;
    }

    h2.system-title {
      font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      line-height: 48px;
      margin-left: 15px;

      span {
        display: inline-block;
      }
    }
  }

  .gtm-login-content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    justify-content: center;

    .gtm-login-box {
      width: 1088px;
      height: 641px;
      position: relative;
      //justify-content: center;
      //align-items: center;
      display: flex;

      .login-box {
        position: absolute;
        right: 0px;
        width: 462px;
        height: 567px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .content-desc {
      position: absolute;
      top: 122px;
      left: 0px;

      .top-line {
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 34px;
        color: #333333;
        line-height: 45px;
        text-align: left;
        font-style: normal;
      }

      .foot-line {
        margin-top: 23px;
        font-family: MicrosoftYaHei;
        font-size: 24px;
        color: #666666;
        line-height: 31px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
      }
    }

    //.login-box {
    //  position: absolute;
    //  right: 0px;
    //  width: 462px;
    //  height: 567px;
    //  top: 50%;
    //  transform: translate(0, -50%);
    //}

    .login-box--username {
      position: absolute;
      right: 0px;
      width: 462px;
      height: 642px;
    }

    .login-form {
      position: relative;
      //right: 421px;
      top: 0px;
      left: 0px;
      width: 460px;
      height: 565px;
      background: linear-gradient(180deg, #f8faff 0%, #ffffff 30%);
      box-shadow: 0px 10px 26px 0px rgba(3, 44, 96, 0.15);
      border-radius: 8px;
      border: 1px solid #ffffff;

      .login-triangle {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 71px;
        height: 71px;
        cursor: pointer;
        background-image: url(../../../assets/images/gtm-login/triangle.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        div {
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 14px;
          color: #ffffff;
          line-height: 19px;
          text-align: right;
          font-style: normal;
          position: relative;
          transform: rotate(45deg);
        }
      }

      .login-triangle-loading {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 71px;
        height: 71px;
        border-width: 71px 71px 0 0;
        background: rgba(255, 255, 255, 0.6);
        border-top-right-radius: 4px;
        z-index: 1000;
      }

      .form-title {
        position: relative;
        top: 50px;
        left: 50px;
        font-family: MicrosoftYaHei;
        font-size: 24px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
    }

    .login-form--register {
      height: 641px;
    }
  }

  .gtm-login-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 88px;
    background: #f5f5f6;
  }
}
</style>
